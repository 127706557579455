import { useEffect, useRef } from 'react'

export default function useIntersectionObserver({
  handleIntersect,
  onIntersect,
  rootMargin,
  enabled = true,
}) {
  const target = useRef()
  useEffect(() => {
    if (!enabled) {
      return
    }
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => (
          handleIntersect && handleIntersect(entry),
          entry.isIntersecting && onIntersect && onIntersect(entry)
        ))
      },
      {
        rootMargin: rootMargin || '0px',
      },
    )

    const el = target && target.current

    if (!el) {
      return
    }

    observer.observe(el)

    return () => {
      observer.unobserve(el)
    }
  }, [target.current, enabled])
  return {
    ref: target,
  }
}
