import React from 'react'
import styled from 'styled-components'
import { Button } from 'components/common/button'
import { Typography } from 'components/common/typography'
import PropTypes from 'prop-types'

export const PinkGradient = `
  color: var(--secondary);
  background-image: linear-gradient(258.75deg, #43B1C8 31.33%, #9989EB 48.42%, #D97FD1 59.35%, #F582B2 65.56%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
`

export const Title = styled.div`
  padding-top: 20px;
  position: relative;
  margin-bottom: 1em;
  ::before {
    content: '';
    position: absolute;
    width: 64px;
    height: 4px;
    background: var(--secondary);
    top: 0;
  }
  @media screen and (min-width: 768px) {
    max-width: ${({ maxWidth }) => `${maxWidth}px` || '524px'};
    background-color: ${({ background }) => (background || 'var(--background)')};
  }
`

const PlatformStyled = styled.div`
  height: fit-content;
  margin: auto 0;
  .description {
    color: var(--lightText);
    margin: 0 0 24px 0;
    line-height: 24px;
  }
  .icon {
    display: block;
    margin-right: 9.33px;
  }
  @media screen and (min-width: 768px) {
    max-width: calc(100% - 360px);
  }
  @media screen and (min-width: 1024px) {
    max-width: 515px;
  }
`

function Platform({
  title, description, path, callToAction, icon,
}) {
  return (
    <PlatformStyled>
      <Title>
        <Typography as="h2" small="headline6" large="headline2">
          {title}
        </Typography>
      </Title>
      <p className="description">{description}</p>
      <Button fontWeight={500} as="a" href={path} target="_blank" rel="noreferrer noopener">
        {icon && <img className="icon" src={icon} alt="icon" />}
        {callToAction}
      </Button>
    </PlatformStyled>
  )
}

Platform.defaultProps = {
  icon: null,
}

Platform.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  callToAction: PropTypes.string.isRequired,
  icon: PropTypes.string,
}

export default Platform
