import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		// fontSize: '1rem',
    marginBottom: '1rem',
	}
}));

export default useStyles;
