
import React from 'react';
import useStyles from './styles';


const DelimiterBlock = ({ style }) => {
  const classes = useStyles();

  return (
    <hr />
  );
};

export default DelimiterBlock;
