/* eslint-disable react/no-array-index-key */
import HeaderBlock from './header'
import ParagraphBlock from './paragraph'
// import ImageOutput from './renderers/imageOutput/index.jsx';
import ListBlock from './list'
// import QuoteOutput from './renderers/quoteOutput/index.jsx';
// import WarningOutput from './renderers/warningOutput/index.jsx';
import TableBlock from './table'
import DelimiterBlock from './delimiter'
import CodeBlock from './code'
import Link from './link'

const BlockOutput = ({
  chunks = [], success, color, id, ...otherProps
}) => chunks.map((block, index) => {
  const { type, data } = block
  switch (type.toLowerCase()) {
    case 'code':
      return <CodeBlock key={index} data={data} color={color} />
    case 'header':
      return <HeaderBlock key={index} data={data} color={color} />
    case 'paragraph':
      return <ParagraphBlock key={id ? `${index}-${id}` : index} data={data} color={color} {...otherProps} />
    // case 'image':
    //   return <ImageOutput data={data} style={style.image || {}} />
    case 'table':
      return <TableBlock key={index} data={data} color={color} />
    case 'list':
      return <ListBlock key={index} data={data} success={success} color={color} {...otherProps} />
    case 'linktool':
      return <Link link={data.link} />
    // case 'quote':
    //   return <QuoteOutput data={data} style={style.quote || {}} />
    // case 'warning':
    //   return <WarningOutput data={data} style={style.warning || {}} />
    case 'delimiter':
      return <DelimiterBlock key={index} color={color} />
    default:
      return <div key={index} />
  }
})

export {
  HeaderBlock, ParagraphBlock, DelimiterBlock, BlockOutput as default,
}
