import styled from 'styled-components'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { Typography } from 'common/typography'
import { SecondaryButton } from 'common/button'
import Wrapper from 'common/wrapper'
import { useInitalProjects } from 'querys/projects'

const NewProjectsStyled = styled.section`
  p {
    margin: 4px 0 24px;
  }
  .projects {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 210px);
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  .cta-container {
    display: flex;
    justify-content: center;
    margin-block-start: 40px;
  }
  @media(min-width: 1024px) {
    .projects {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(4, 210px);
      grid-column-gap: 24px;
      grid-row-gap: 24px;
    }
    p {
      margin: 16px 0 24px;
    }
  }
`

const NewProjectCardStyled = styled.li`
  display: block;
  position: relative;
  color: var(--white);
  a {
    display: block;
    height: 100%;
  }
  h3 {
    max-width: 100%;
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  &:hover .new-project-overlay {
    background: rgba(4, 0, 30, 0.5);
    visibility: visible;
    opacity: 1;
    backdrop-filter: blur(8px);
  }
  .new-project-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    visibility: hidden;
    padding: 24px;
    opacity: 0;
    transition: 0.3s ease-in all;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    inset: 0;
  }
  @media(min-width: 1024px) {
    ${({ order }) => order === 0 && 'grid-area: 1 / 1 / 3 / 3;'}
    ${({ order }) => order === 5 && 'grid-area: 3 / 2 / 5 / 4;'}
    .new-project-overlay {
      h3 {
        margin-bottom: 16px;
        ${({ order }) => order === 0 && 'font: var(--headline2);'}
        ${({ order }) => order === 5 && 'font: var(--headline2);'}
      }
    }
  }
`


function NewProjectCard({
  title, slug, cover_image, order,
}) {
  return (
    <NewProjectCardStyled order={order}>
      <a href={`/proyectos/${slug}`}>
        <Image src={cover_image} layout="fill" objectFit="cover" alt={`Thumbnail del proyecto ${title}`} />
        <div className="new-project-overlay">
          <Typography as="h3" id={`new-${slug}`} font="headline6" color="baseNeutral">
            {title}
          </Typography>
          <SecondaryButton medium>
            Ver proyecto
          </SecondaryButton>
        </div>
      </a>
    </NewProjectCardStyled>
  )
}


function NewProjects() {
  const { data, isLoading, isError } = useInitalProjects()

  if (isLoading || isError) return null

  return (
    <NewProjectsStyled aria-labelledby="new-projects">
      <Wrapper maxWidth="1360">
        <Typography as="h2" id="new-projects" small="headline5" large="headline3">
          Proyectos nuevos cada semana
        </Typography>
        <Typography as="p" small="regular">
          Construye un portafolio profesional basado en proyectos reales y retadores.
        </Typography>
        <ul className="projects">
          {data.results.slice(0, 6).map((project, idx) => (
            <NewProjectCard key={project.slug} order={idx} {...project} />))}
        </ul>
        <div className="cta-container">
          <Link href="/proyectos/todos" passHref>
            <SecondaryButton fullWidth fitContent>
              Ver todos los proyectos
            </SecondaryButton>
          </Link>
        </div>
      </Wrapper>
    </NewProjectsStyled>
  )
}

export default NewProjects
