import styled from 'styled-components'
import { MdArrowBack, MdArrowForward } from 'react-icons/md'
import { TransparentButton } from 'common/button'

const ArrowBtnsStyled = styled.div`
  display: flex;
  gap: 1rem;
  @media(min-width: 1024px) {
    gap: 1.5rem;
  }
`

const BoxButton = styled(TransparentButton)`
  block-size: 40px;
  inline-size: 40px;
  background: var(--baseSecondary);
  color: var(--basePrimary);
  cursor: pointer;
  ${({ disabled }) => (disabled ? `
    background: var(--neutral80);
    cursor: not-allowed;
  ` : `
    &:hover {
      background: #ECECEC;
    }
    &:active {
      background: #DDDDDD;
    }
  `)}
  svg {
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    block-size: 56px;
    inline-size: 56px;
  }
`

function ArrowBtns({ disabled, ariaLabel, previousClick, nextClick }) {
  return (
    <ArrowBtnsStyled>
      <BoxButton onClick={(e) => previousClick(e)} disabled={disabled} aria-label={`${ariaLabel} anteriores`}>
        <MdArrowBack size={24} color="inherit" />
      </BoxButton>
      <BoxButton onClick={(e) => nextClick(e)} disabled={disabled} aria-label={`${ariaLabel} siguientes`}>
        <MdArrowForward size={24} color="inherit" />
      </BoxButton>
    </ArrowBtnsStyled>
  )
}

export default ArrowBtns
