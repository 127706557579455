import styled from 'styled-components'
import { useState } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { SecondaryButton } from 'common/button'
import { Typography } from 'common/typography'
import { PlayAnimated, playAnimation } from 'components/icons'
import Wrapper from 'common/wrapper'
import useSession from 'querys/session'

const VideoCardLazy = dynamic(
  () => import('../course/video-card-lazy'),
  {
    loading: () => (
      <div className="projects-video-thumb">
      </div>
    ),
  },
)

const FreeLessonStyled = styled.section`
  margin-block-start: 80px;
  margin-block-end: 80px;
  p {
    margin: 8px 0 16px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .free-lesson {
    &-img {
      position: relative;
      box-shadow: 0px 0px 130px rgba(97, 112, 216, 0.34);
    }
    &-video {
      position: absolute;
      top: 5%;
      bottom: 11%;
      z-index: 1;
      right: 0;
      left: 14%;
    }
    &-content {
      margin-block-start: 24px;
      text-align: center;
    }
    &-thumb {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      cursor: pointer;
      ${playAnimation}
    }
  }
  @media(min-width: 768px) {
    margin-block-start: 160px;
    margin-block-end: 160px;
    .free-lesson {
      display: flex;
      align-items: center;
      gap: 24px;
      &-content {
        text-align: left;
        margin-block-start: 0;
      }
    }
    p {
      margin: 16px 0 24px;
    }
  }
`

function FreeLesson() {
  const [playing, setPlaying] = useState(false)
  const { token } = useSession()

  const url = 'https://player.vimeo.com/video/566710098'

  return (
    <FreeLessonStyled aria-labelledby="free-lesson">
      <Wrapper maxWidth={1360}>
        <div className="free-lesson">
          <div className="free-lesson-img">
            <img width="783" height="441" src="/images/home/free-lesson.png" alt="Marco del video de una clase" loading="lazy" />
            <div className="free-lesson-video">
              {!playing
                ? (
                  <div className="free-lesson-thumb" onClick={() => setPlaying(true)} aria-label="Play the video" role="button">
                    <PlayAnimated />
                  </div>)
                : (
                  <VideoCardLazy videoUrl={url} />
                )
              }
            </div>
          </div>
          <div className="free-lesson-content">
            <Typography as="h2" small="headline5" large="headline3" id="free-lesson" color="baseNeutral">
              Toma una clase de prueba
            </Typography>
            <Typography>
              Regístrate ahora y accede a gran variedad de cursos y proyectos gratuitos.
            </Typography>
            {token ? (
              <Link href="/aprender" passHref>
                <SecondaryButton fullWidth fitContent responsive>
                  Ir al plan de estudios
                </SecondaryButton>
              </Link>
            ) : (
              <Link href="/registro" passHref>
                <SecondaryButton fullWidth fitContent responsive>
                  ¡Quiero registrarme!
                </SecondaryButton>
              </Link>
            )}
          </div>
        </div>
      </Wrapper>
    </FreeLessonStyled>
  )
}

export default FreeLesson
