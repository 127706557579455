import styled from 'styled-components'

const StackStyled = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .stack-item {
    font-size: 12px;
    line-height: 20px;
    padding: 2px 4px;
    border-radius: 4px;
    color: var(--white);
    background: var(--neutral90);
  }
`

function Stack({ stack }) {
  return (
    <StackStyled>
      {stack.map((item) => (
        <li className="stack-item" key={item}>{item.toUpperCase()}</li>
      ))}
    </StackStyled>
  )
}

export default Stack
