import styled from 'styled-components'
import PropTypes from 'prop-types'

const LinkStyled = styled.a`
  color: var(--primary);
  transition: 0.2s ease-in-out;
  &:hover {
    color: var(--primaryHover);
    text-decoration: underline;
  }
`

function Link({ link }) {
  return (
    <p>
      <LinkStyled href={link} target="_blank" rel="noopener noreferrer">
        {link}
      </LinkStyled>
    </p>
  )
}

Link.propTypes = {
  link: PropTypes.string.isRequired,
}

export default Link
