import styled from 'styled-components'
import Link from 'next/link'
import Image from 'next/image'
import Wrapper from 'common/wrapper'
import { Typography } from 'common/typography'
import { SecondaryButton } from 'common/button'

const LearningPlanStyled = styled.section`
  margin-block-start: 80px;
  margin-block-end: 80px;
  .learning-miniature {
    box-shadow: 0px 0px 190px rgb(97 112 216 / 34%);
    margin-block-end: 24px;
    position: relative;
    aspect-ratio: 4 / 3;
  }
  p {
    margin-block-start: 8px;
    margin-block-end: 16px;
  }
  @media(min-width: 1024px) {
    text-align: left;
    margin-block-start: 160px;
    margin-block-end: 160px;
    p {
      margin-block-start: 16px;
      margin-block-end: 24px;
    }
    .learning-plan-container {
      display: flex;
      gap: 24px;
    }
    .learning-miniature {
      flex: 1.4;
      order: 2;
    }
    .learning-plan-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
  }
`

function LearningPlan() {
  return (
    <LearningPlanStyled aria-labelledby="learning-plan">
      <Wrapper maxWidth="1360">
        <div className="learning-plan-container">
          <div className="learning-miniature">
            <Image
              fill
              src="/images/home/plan-de-estudios.png"
              alt="miniatura de nuestro plan de estudios"
              quality={100}
              style={{ objectFit: 'contain' }}
            />
          </div>
          <div className="learning-plan-content">
            <Typography as="h2" id="learning-plan" small="headline5" large="headline3" color="baseNeutral">
              Plan de estudio de 0 a profesional
            </Typography>
            <Typography as="p" small="p2Regular" large="regular">
              Sigue el plan de estudio y desarrolla habilidades que te permitirán avanzar en tu carrera profesional.
            </Typography>
            <Link href="/aprender" passHref>
              <SecondaryButton fullWidth fitContent responsive>
                Ver plan de estudios
              </SecondaryButton>
            </Link>
          </div>
        </div>
      </Wrapper>
    </LearningPlanStyled>
  )
}

export default LearningPlan
