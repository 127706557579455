import { useState } from 'react'
import { Typography } from 'common/typography'
import Wrapper from 'common/wrapper'
import styled from 'styled-components'
import { useCourses } from 'querys/course'
import ArrowBtns from 'home/arrow-btns'
import LatestRelease from './latest-release'

const LatestReleasesStyled = styled.section`
  margin-block-end: 6rem;
  .realeses-list {
    overflow: hidden;
    position: relative;
    margin-block-start: 24px;
    block-size: 400px;
  }
  .latest-realeses {
    position: relative;
  }
  .arrow-btns-container {
    top: 72px;
    right: 1rem;
    position: absolute;
  }
  @media(min-width: 1024px) {
    .realeses-list {
      block-size: 551px;
    }
    .arrow-btns-container {
      top: initial;
      bottom: 4rem;
      right: 4rem;
    }
  }
  @media (min-width: 1920px) {
    .realeses-list {
      block-size: 750px;
    }
  }
`

function LatestReleases() {
  const [currentSlide, setCurrentSlide] = useState(0)
  const maxSlide = 3
  const { data: initialData, isError } = useCourses()
  if (!initialData || isError) return null
  const data = [...initialData]

  const lastCourses = data.filter(({ status }) => status !== 'COMING_SOON').sort(({ created_at: first }, { created_at: second }) => {
    const firstDate = parseInt(first.slice(0, 10).replace(/-/g, ''), 10)
    const secondDate = parseInt(second.slice(0, 10).replace(/-/g, ''), 10)
    if (firstDate > secondDate) return -1
    if (firstDate < secondDate) return 1
    return 0
  })
  const courses = lastCourses.slice(0, 4)

  const handleNextSlide = () => {
    let newState

    if (currentSlide === maxSlide) {
      newState = 0
    } else {
      newState = currentSlide + 1
    }

    document.querySelectorAll('#release-slide').forEach((slide, idx) => {
      slide.style.transform = `translateX(${100 * (idx - newState)}%)`
    })
    setCurrentSlide(newState)
  }

  const handlePreviusSlide = () => {
    let newState

    if (currentSlide === 0) {
      newState = maxSlide
    } else {
      newState = currentSlide - 1
    }

    document.querySelectorAll('#release-slide').forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - newState)}%)`
    })
    setCurrentSlide(newState)
  }

  return (
    <LatestReleasesStyled aria-labelledby="latest">
      <Wrapper maxWidth={1360}>
        <div className="latest-realeses">
          <Typography small="headline5" large="headline3" color="var(--baseNeutral)" id="latest">
            Últimos lanzamientos
          </Typography>
          <ul className="realeses-list">
            {courses.map((item, idx) => (
              <LatestRelease key={item.uuid} {...item} idx={idx} />
            ))}
          </ul>
          <div className="arrow-btns-container">
            <ArrowBtns
              previousClick={handlePreviusSlide}
              nextClick={handleNextSlide}
              ariaLabel="Ver cursos"
            />
          </div>
        </div>
      </Wrapper>
    </LatestReleasesStyled>
  )
}

export default LatestReleases
