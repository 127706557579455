import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import Wrapper from 'common/wrapper'
import { Typography } from 'common/typography'
// import ArrowBtns from 'home/arrow-btns'
import Link from 'next/link'
import Image from 'next/legacy/image'
// import { useState } from 'react'
function transform(node) {
  if (node.type === 'tag' && node.name === 'a') {
    return null;
  }
}
const LessonsCarouselStyled = styled.section`
  margin-block-start: 4rem;
  margin-block-end: 4rem;
  block-size: 86vw;
  overflow: hidden;
  .latest-lesson-nav {
    display: flex;
    justify-content: space-between;
    margin-block-end: 1.5rem;
  }
  .latest-lessons {
    position: relative;
    display: flex;
  }
  .carousel-scroll {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }
  @media (min-width: 630px) {
    margin-block-start: 5rem;
    margin-block-end: 5rem;
    block-size: 514px;
  }
`

const LatestLesson = styled.li`
  display: block;
  position: relative;
  max-inline-size: 90%;
  flex-shrink: 0;
  /* transform: translateX(${({ translate }) => `${translate}%`}); */
  scroll-snap-align: end;
  padding-inline-end: 1rem;
  .lesson-thumb {
    border-radius: 4px;
    overflow: hidden;
    &-img {
      width: 100%;
      height: auto;
    }
  }
  .lesson-title {
    margin-block-start: 16px;
    margin-block-end: 4px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .lesson-description {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .lesson-course {
    position: absolute;
    border-radius: 8px;
    z-index: 1;
    top: 2px;
    left: 2px;
    display: flex;
    gap: 4px;
    align-items: center;
    &-title {
      max-inline-size: 72%;
    }
    &-img {
      block-size: 24px;
      inline-size: 24px;
      border-radius: 50%;
    }
  }
  @media (min-width: 630px) {
    padding-inline-end: 1.5rem;
    max-inline-size: 540px;
    .lesson-course {
      background: #1f1f3a80;
      padding: .5rem;
      gap: .5rem;
      top: 10px;
      left: 10px;
      &-title {
        max-inline-size: 100%;
      }
      &-img {
        block-size: 40px;
        inline-size: 40px;
      }
    }
  }
`

function LessonsCarousel({
  title, data, containerId, itemId, sliderItem, max: { mobile, desktop },
}) {
  // const [currentSlide, setCurrentSlide] = useState(0)

  // const handleNextSlide = () => {
  //   const sliders = document.querySelectorAll(`#${containerId}`)
  //   const sliderArr = [...sliders]
  //   sliderArr.find((item, idx) => {
  //     if (!item.getBoundingClientRect().right > window.innerWidth) {
  //       item.scrollIntoView(({ behavior: 'smooth', block: 'nearest', inline: 'center' }))
  //     }
  //   })
  // }

  // const handlePreviusSlide = () => {
  // }

  return (
    <LessonsCarouselStyled aria-labelledby={`${containerId}-al`}>
      <div>
        <Wrapper maxWidth={1366}>
          <div className="latest-lesson-nav" id={containerId}>
            <Typography small="headline5" large="headline3" id={`${containerId}-al`} color="var(--baseNeutral)">
              {title}
            </Typography>
            {/* <ArrowBtns
              previousClick={handlePreviusSlide}
              nextClick={handleNextSlide}
              ariaLabel="Ver cursos"
            /> */}
          </div>
        </Wrapper>
      </div>
      <div className="carousel-scroll">
        <Wrapper maxWidth={1366}>
          <ul className="latest-lessons">
            {data?.map(({
              course, title, thumbnail, description, slug,
            }, idx) => (
              <LatestLesson id={sliderItem} key={slug} translate={idx * 100}>
                <Link href={`/aprender/${course.slug}/${slug}`} id={itemId}>
                  <div className="lesson-thumb">
                    <Image objectFit="cover" className="lesson-thumb-img" width="538" height="330" src={thumbnail} alt={course.title} />
                  </div>
                  <Typography as="h3" small="regularBold" large="headline5" className="lesson-title">
                    {title}
                  </Typography>
                  <Typography small="p2Regular" large="regular" className="lesson-description">
                    {ReactHtmlParser(description, { transform })}
                  </Typography>
                  <div className="lesson-course">
                    <Image width="40" height="40" src={course.badge} alt={course.title} className="lesson-course-img" />
                    <Typography as="h4" small="smallBold" large="regularBold" className="lesson-course-title">
                      {course.title}
                    </Typography>
                  </div>
                </Link>
              </LatestLesson>
            ))}
          </ul>
        </Wrapper>
      </div>
    </LessonsCarouselStyled>
  )
}

export default LessonsCarousel
