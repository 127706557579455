import { useRouter } from 'next/router'
import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query'
import {
  studentProjectsService,
  studentProjectService,
  studentProjectsPodiumService,
} from 'services/projects'

export function useStudentProjects({ page_size }) {
  const { query } = useRouter()
  const queryClient = useQueryClient()
  const size = query.page_size || page_size || null
  const level = query.project__level || null
  const property = query.property || null
  const type = query.project__type || null
  const search = query.search || null
  return useInfiniteQuery(
    ['STUDENT_PROJECTS', size, level, property, type, search],
    ({ pageParam }) => studentProjectsService({
      size, page: pageParam, level, property, type, search,
    }),
    {
      getNextPageParam: ({ next }) => {
        const nextParam = next?.slice(next?.indexOf('page=') + 5)
        return nextParam
      },
      getPreviousPageParam: ({ previous }) => {
        const previousParam = previous?.slice(previous?.indexOf('page=') + 5)
        return previousParam
      },
      placeholderData: () => {
        const queryCacheData = queryClient.getQueryData(['STUDENT_PROJECTS', null, null, null, null, null])
        if (queryCacheData) {
          return {
            pageParams: [null, '2'],
            pages: [queryCacheData],
          }
        }
      },
      staleTime: Infinity,
      refetchOnMount: false,
    },
  )
}


export async function prefetchStudentProjects({ queryClient, page_size }) {
  await queryClient.prefetchInfiniteQuery(
    ['STUDENT_PROJECTS', page_size, null, null, null, null],
    () => studentProjectsService({ size: page_size }),
  )
  return queryClient
}

export function useStudentProject() {
  const { query: { uuid } } = useRouter()
  return useQuery(
    ['STUDENT_PROJECT', uuid],
    () => studentProjectService({ uuid }),
    {
      staleTime: Infinity,
      refetchOnMount: false,
    },
  )
}

export async function prefetchStudentProject({ queryClient, uuid }) {
  await queryClient.prefetchQuery(
    ['STUDENT_PROJECTS', uuid],
    () => studentProjectService({ uuid }),
  )
  return queryClient
}

export function useStudentsPodium({ defaultPeriod = 'weekly' }) {
  // defaultPeriod can be monthly, yearly, weekly
  const { query: { period } } = useRouter()
  const queryFilter = period || defaultPeriod || 'monthly'
  return useQuery(
    ['STUDENT_PROJECTS_PODIUM', queryFilter],
    () => studentProjectsPodiumService({ filter: queryFilter }),
    {
      staleTime: Infinity,
      refetchOnMount: false,
    },
  )
}

export async function prefetchStudentsPodium({ queryClient, filter }) {
  const period = filter || 'monthly'
  await queryClient.prefetchQuery(
    ['STUDENT_PROJECTS_PODIUM', period],
    () => studentProjectsPodiumService({ filter }),
  )
  return queryClient
}
