import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		margin: '8px 0',
		marginTop: '15px',
		fontWeight: 600,
    marginBottom: '1rem',
	}
}));

export default useStyles;