import styled from 'styled-components'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { PrimaryButton } from 'common/button'
import { Typography } from 'common/typography'
import Stack from 'projects/stack'

export const ButtonOutline = styled(PrimaryButton)`
  border: 1px solid var(--white);
  color: var(--white);
  background: var(--neutral100);
  border-radius: 12px;
  padding-block-start: 6px;
  padding-block-end: 10px;
  box-shadow: inset 0px -3px 0px var(--white);
  &:hover {
    background: var(--neutral90);
  }
  &:active {
    background: var(--neutral110);
  }
`

const ProjectCompletedStyled = styled.li`
  overflow: hidden;
  border-radius: 8px;
  color: var(--white);
  background: var(--neutral100);
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in filter;
  &:hover {
    filter: drop-shadow(0px 2px 24px rgba(97, 112, 216, 0.4));
  }
  a {
    flex: 1;
    display: grid;
    grid-template-rows: min-content 1fr;
  }
  .stack-container {
    margin-block-end: 20px;
  }
  .project-completed {
    border-radius: 0 0 8px 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: var(--neutral50);
    margin-block-start: 4px;
    margin-block-end: 16px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .student {
    display: flex;
    margin-block-end: 24px;
    gap: 8px;
    align-items: center;
    color: var(--neutral50);
    &-photo {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(51.32deg, #FA057C 0%, #0704FE 100%, rgba(196, 196, 196, 0) 100%);
      border-radius: 50%;
      overflow: hidden;
      &-container {
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
      }
    }
    &-name {
      font: var(--smallRegular);
      margin: 0;
    }
  }
  .btn-container {
    flex: 1;
    display: flex;
    align-items: flex-end;
  }
  @media (min-width: 1024px) {
    margin-block-end: 0px;
    flex: 1;
  }
`

function ProjectCompletedCard({
  title, stack, thumbnail, student, description,
}) {
  const { photo, full_name } = student
  return (
    <>
      <div>
        <Image
          src={thumbnail}
          layout="responsive"
          width={282}
          objectFit="cover"
          height={159}
          alt={description}
        />
      </div>
      <div className="project-completed">
        <Typography color="var(--white)" as="h3" small="headline6">
          {title}
        </Typography>
        <p>{description}</p>
        <div className="stack-container">
          <Stack stack={stack} />
        </div>
        <div className="student">
          <div className="student-photo">
            <div className="student-photo-container">
              <Image layout="fixed" src={photo} alt={full_name} width={24} height={24} />
            </div>
          </div>
          <p className="student-name">{full_name}</p>
        </div>
        <div className="btn-container">
          <ButtonOutline fullWidth medium>
            Ver proyecto
          </ButtonOutline>
        </div>
      </div>
    </>
  )
}

function ProjectCompleted({ uuid, ...props }) {
  return (
    <ProjectCompletedStyled data-cy="project-completed">
      <Link href={`/proyectos/estudiantes/${uuid}`} passHref>
        <ProjectCompletedCard {...props} />
      </Link>
    </ProjectCompletedStyled>
  )
}

export default ProjectCompleted
