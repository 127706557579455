import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FiLink2 } from 'react-icons/fi'
import useStyles from './styles'

const P = styled.p`
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
  font: ${({ font }) => `var(--${font})` || 'var(--regular)' };
  &.link {
    display: flex!important;
    color: var(--turquoise)!important;
    svg {
      margin-right: 8px;
    }
  }
`

const ParagraphBlock = ({
  data, style, color, margin, font, lineHeight, linkStyled,
}) => {
  const classes = useStyles()

  if (!data) return ''
  if (!style || typeof style !== 'object') style = {}

  let content = null

  if (typeof data === 'string') content = data
  else if (typeof data === 'object' && data.text && typeof data.text === 'string') content = data.text
  const isLink = content.includes('<a')
  if (isLink) content = content.replace('<a href', '<a target=”_blank” rel="noopener noreferrer" href')
  const blueLink = isLink && linkStyled
  return content ? (
    <P className={blueLink ? `${classes.root} link` : classes.root} color={color} margin={margin} font={font} lineHeight={lineHeight}>
      {blueLink && (<FiLink2 size="24" />)}
      {ReactHtmlParser(content)}
    </P>
  ) : ''
}

ParagraphBlock.defaultProps = {
  data: { text: '' },
  style: { bool: false },
  success: false,
  color: 'var(--baseSecondary)',
  linkStyled: false,
}

ParagraphBlock.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string,
  }),
  style: PropTypes.shape({
    none: PropTypes.bool,
  }),
  success: PropTypes.bool,
  linkStyled: PropTypes.bool,
  color: PropTypes.string,
}

export default ParagraphBlock
