import styled from 'styled-components'
import Link from 'next/link'
import { Typography } from 'common/typography'
import { ButtonOutline, SecondaryButton } from 'common/button'
import BlockOutput from 'content-renderer'

const LatestReleaseStyled = styled.li`
  block-size: 400px;
  box-sizing: border-box;
  padding: 1rem;
  position: absolute;
  border-radius: 4px;
  background:
    linear-gradient(360deg, #000000 27.25%, rgba(0, 0, 0, 0) 116.67%),
    url(${({ thumbnail }) => `'${thumbnail}'`})
  ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translateX(${({ translate }) => `${translate}%`});
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-inline-size: 100%;
  .btns-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-block-start: 1rem;
  }
  .latest-realese-header {
    display: flex;
    justify-content: space-between;
  }
  .lastest-realese-background {
    background: red;
    position: absolute;
    inset: 0;
    z-index: -1;
  }
  .logo {
    block-size: 40px;
    inline-size: 40px;
  }
  h3 {
    overflow: hidden;
    margin-block-end: .5rem;
  }
  .latest-realese-content p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  @media (min-width: 500px) {
    .btns-container {
      flex-direction: row;
    }
  }
  @media (min-width: 1024px) {
    padding: 4rem;
    block-size: 551px;
    background:
      linear-gradient(90deg, #000000 7%, rgba(0, 0, 0, 0) 73%),
      linear-gradient(13deg,#000000 5%, rgba(0,0,0,0) 35%),
      url(${({ thumbnail }) => `'${thumbnail}'`})
    ;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .logo {
      block-size: 80px;
      inline-size: 80px;
    }
    .latest-realese-content {
      max-inline-size: 800px;
    }
    h3 {
      margin-block-end: 1rem;
    }
    .btns-container {
      margin-block-start: 2rem;
    }
  }
  .discount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background: #FB0000;
    border-radius: 50%;
    block-size: 50px;
    padding: 5px;
    box-sizing: border-box;
  }
  @media (min-width: 768px) {
    .discount {
      block-size: 90px;
    }
  }
  @media (min-width: 1920px) {
    min-inline-size: 1360px;
    block-size: 750px;
    .latest-realese-content {
      max-inline-size: 600px;
    }
    h3 {
      overflow: hidden;
      display: block;
      font: var(--headline3);
    }
  }
`

function LatestRelease({
  title, summary, slug, featured_video, idx,
}) {
  const big_thumbnail = featured_video?.thumbnails[2]

  return (
    <LatestReleaseStyled id="release-slide" thumbnail={big_thumbnail?.link} translate={idx * 100}>
      {/* {slug === 'react' ? (
        <div className="discount">
          <Typography small="headline6" large="headline4">
            -30%
          </Typography>
        </div>
      ) : null} */}
      <div className="latest-realese-header">
        <img
          width="80"
          height="80"
          src="/icons/icon-120x120.png"
          className="logo"
          alt="El logotipo de la plataforma, un cohete formado por dos triangulos de diamante"
        />
      </div>
      <div className="latest-realese-content">
        <Typography as="h3" small="headline6" large="headline4" color="var(--neutral10)">
          {title}
        </Typography>
        <BlockOutput
          color="var(--paragraphColor)"
          success
          chunks={summary?.blocks}
        />
        <div className="btns-container">
          <Link href="/comprar/anual" passHref>
            <SecondaryButton>
              Suscribirme
            </SecondaryButton>
          </Link>
          <Link href={`/cursos/${slug}`} passHref>
            <ButtonOutline>
              Ver curso
            </ButtonOutline>
          </Link>
        </div>
      </div>
    </LatestReleaseStyled>
  )
}

export default LatestRelease
