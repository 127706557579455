import handlePromise from 'utils/handle-promise'

export async function getCourseLessonsService({ course, token }) {
  const url = `/api/v1/courses/${course}/lessons/`
  const data = await handlePromise(url, {
    method: 'GET',
    headers: { Authorization: token },
  })
  return data
}

// TODO: RES
export async function getLessonService({
  course, lesson_slug, token, res, req,
}) {
  const url = `/api/v1/courses/${course}/lessons/${lesson_slug}/`
  const data = await handlePromise(url, {
    method: 'GET',
    headers: token ? { Authorization: token } : {},
  }, res, req)
  return data
}

export async function lessonCompletedService({ course, lessonSlug, token }) {
  const url = `/api/v1/courses/${course}/lessons/${lessonSlug}/done/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
  })
  return data
}

// TODO DELETE THIS WITH REACT QUERY
export async function postLessonMarkAsDone({ course_slug, lesson_slug, token }) {
  const url = `/api/v1/courses/${course_slug}/lessons/${lesson_slug}/done/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
  })
  return data
}

export async function videoProgressService({
  course, lesson_slug, token, progress,
}) {
  const formData = new FormData()
  formData.append('progress', Math.floor(progress))
  const url = `/api/v1/courses/${course}/lessons/${lesson_slug}/video-progress/`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
    body: formData,
  })
  return data
}


export async function lessonFavoriteService({ lesson_id, token, favorite }) {
  const query = `?favorite=${favorite}`
  const url = `/api/v1/lessons/favorites/${lesson_id}/${query}`
  const data = await handlePromise(url, {
    method: 'POST',
    headers: { Authorization: token },
  })
  return data
}

export async function getLatestLessonsService() {
  const url = '/api/v1/lessons/latest/'
  const data = await handlePromise(url, {
    method: 'GET',
  })
  return data
}
