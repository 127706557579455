
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import useStyle from './styles';


const HeaderBlock = ({ data, style }) => {
  const classes = useStyle();

  if (!data) return '';
  if (!style || typeof style !== 'object') style = {};

  let content = null;

  if (typeof data === 'string') content = data;
  else if (typeof data === 'object' && data.text && typeof data.text === 'string') content = data.text;

  if (!content) return '';
  if (typeof data === 'object' && data.level && typeof data.level === 'number') {
    switch (data.level) {
      case 1: return <h1 className={ classes.root }>{ ReactHtmlParser(content) }</h1>;
      case 2: return <h2 className={ classes.root }>{ ReactHtmlParser(content) }</h2>;
      case 3: return <h3 className={ classes.root }>{ ReactHtmlParser(content) }</h3>;
      case 4: return <h4 className={ classes.root }>{ ReactHtmlParser(content) }</h4>;
      case 5: return <h5 className={ classes.root }>{ ReactHtmlParser(content) }</h5>;
      case 6: return <h6 className={ classes.root }>{ ReactHtmlParser(content) }</h6>;
      default: return <h4 className={ classes.root }>{ ReactHtmlParser(content) }</h4>;
    }
  }

  return <h4 className={ classes.root }>{ ReactHtmlParser(content) }</h4>;
};

export default HeaderBlock;
