
const styles = (theme) => ({
	container: {
    width: '100%',
  },
  code: {
    width: '100%',
    minHeight: '30px',
    boxSizing: 'border-box',
    textAlign: 'left',
    margin: '8px 0',
    marginTop: '15px',
    padding: '0',
    borderRadius: '2px 2px 2px 0',
    border: 'none !important',
    outline: 'none !important',
    fontSize: '14px',
  }
});

export default styles;
