
import React from 'react'
import ReactHtmlParser from 'react-html-parser'
import styled from 'styled-components'
import { Success } from 'components/icons/index'
import { FiLink2 } from 'react-icons/fi'
import useStyles from './styles'

const P = styled.p`
  line-height: 24px;
  font-size: 1em;
`

const LiStyled = styled.li`
  color: ${({ color }) => color};
`

const validListStyles = ['ordered', 'unordered']

const ListBlock = ({
  data, style, success, color, linkStyled,
}) => {
  const classes = useStyles()

  if (!data) return ''
  if (!style || typeof style !== 'object') style = {}


  let content = []
  let listType = 'unordered'

  if (typeof data === 'string') content.push(data)
  else if (typeof data === 'object') {
    if (data.items && Array.isArray(data.items)) {
      content = data.items.map((item, index) => {
        if (success) {
          return (
            <P key={index}>
              <Success margin="0 18.29px 0 0" width="18.41" height="14.12" color={color} />
              {ReactHtmlParser(item)}
            </P>
          )
        }
        const isLink = item.includes('<a')
        let render = item
        if (isLink) render = item.replace('<a href', '<a target=”_blank” rel="noopener noreferrer" href')
        const blueLink = isLink && linkStyled
        return (
          <LiStyled key={index} color={color}>
            {blueLink && (<FiLink2 size="24" />)}
            {ReactHtmlParser(render) }
          </LiStyled>
        )
      })
    }

    if (data.style && validListStyles.includes(data.style)) listType = data.style
  }

  if (content.length <= 0) return ''

  if (listType === 'ordered') {
    return (
      <ol className={classes.root}>{ content }</ol>
    )
  }

  return <ul className={classes.root}>{ content }</ul>
}

export default ListBlock
