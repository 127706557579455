import styled from 'styled-components'
import Wrapper from 'common/wrapper'
import { Typography } from 'common/typography'
import { Button } from 'common/button'
import Link from 'next/link'

const PublicPorfolioSectionStyled = styled.section`
  overflow: hidden;
  padding: 32px 0 0;
  margin: 80px 0;
  background: linear-gradient(225.58deg, #0704FE -14.51%, #FA057C 157.11%);
  color: var(--white);
  .banner-tag {
    padding: 4px 8px;
    border-radius: 40px;
    font: var(--headline6);
    color: var(--neutral110);
    background: #FFDD66;
    display: block;
    width: fit-content;
    margin-block-end: 1rem;
  }
  .banner-description {
    margin: 16px 0 24px;
  }
  .banner-img {
    max-inline-size: 690px;
    max-block-size: 389px;
    margin: 0 auto;
    margin-block-start: 40px;
    img {
      max-width: 100%;
      height: auto;
      filter: drop-shadow(0px 0px 40px #dacee880);
      margin: 0 auto;
    }
  }
  @media(min-width: 768px) {
    padding: 48px 0 0;
    margin: 80px 0;
    .banner-img {
      margin-block-start: 60px;
    }
  }
  @media(min-width: 1024px) {
    padding: 80px 0 80px;
    margin: 80px 0;
    .banner-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .banner-img {
      flex: 1;
      margin-block-start: 0px;
    }
    .banner-content {
      max-width: 630px;
    }
  }
  @media(min-width: 1366px) {
    padding: 80px 0 0px;
  }
`

const WhiteButton = styled(Button)`
  background: var(--white);
  color: var(--neutral100);
  &:hover {
    background: #ECECEC!important;
  }
  &:active {
    background: #DDDDDD!important;
  }
`

function PublicPorfolioSection() {
  return (
    <PublicPorfolioSectionStyled aria-labelledby="create-your-portfolio">
      <Wrapper maxWidth={1366}>
        <Link href="/estudiante/portafolio" className="banner-container">
          <div className="banner-content">
            <Typography as="h2" font="headline4" id="create-your-portfolio">
                Crea tu propio portafolio
            </Typography>
            <Typography font="regular" className="banner-description">
                Nos importa demasiado que muestres tus habilidades y conocimientos al mundo,
              {' '}
                por ello te facilitamos las herramientas necesarias para que puedas generar un
              <b> Portafolio Público </b>
                utilizando los proyectos que desees.
            </Typography>
            <WhiteButton fullWidth fitContent>
                Crear Portafolio
            </WhiteButton>
          </div>
          <div className="banner-img">
            <img
              src="/images/portfolio/public-portfolio-section.webp"
              alt="Previsualización del portafolio publico"
              width="650"
              height="408"
            />
          </div>
        </Link>
      </Wrapper>
    </PublicPorfolioSectionStyled>
  )
}

export default PublicPorfolioSection
