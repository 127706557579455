import { createGlobalStyle } from 'styled-components'
import { useEffect } from 'react'
import { dehydrate } from 'react-query/hydration'
import { QueryClient } from 'react-query'
import Navigation from 'components/navigation'
import Footer from 'common/footer'
import ComunityReviews from 'common/community-reviews'
import Testimonies from 'home/testimonies'
import PlanetSection from 'home/planet-section'
import NewProjects from 'home/projects/new-projects'
import LatestReleases from 'home/courses/latest-releases'
import ProjectsCompleted from 'home/projects/projects-completed'
import LearningPlan from 'home/learning-plan'
import FreeLesson from 'home/free-lesson'
import PublicPorfolioSection from 'home/public-portfolio-section'
import Hero from 'home/hero'
import ProgresiveLearning from 'course/progressive-learning/progressive-learning'
import { prefetchCourses } from 'querys/course'
import { prefetchSubscriptionPlans } from 'querys/subscriptions'
import { prefetchProjects } from 'querys/projects'
import { prefetchStudentProjects } from 'querys/student-projects'
import { prefetchTotalUsers } from 'querys/growth'
import PricingSection from 'components/prices/pricing-section'
import LessonsCarousel from 'home/lessons-carousel'
import { prefetchLatestLessons, useLatestLesssons } from 'querys/lesson'
import popularLesson from 'home/popular-lesson.json'
import { addWindowsClassToBody } from 'utils/get-os'
import { customScrollBar } from 'common/scroll-bar'
import Divider from 'common/divider'

const WindowsScrollBanner = createGlobalStyle`
  .windows .carousel-scroll {
    overflow-x: overlay;
    ${customScrollBar}
  }
`

function Home() {
  const { data } = useLatestLesssons()

  useEffect(() => {
    addWindowsClassToBody()
  }, [])

  return (
    <>
      <WindowsScrollBanner />
      <Navigation position="sticky" maxWidth="1360px" />
      <Hero />
      <LatestReleases />
      <LessonsCarousel
        title="Últimas clases"
        data={data}
        containerId="latest-lessons-container"
        itemId="latest-lessons-item"
        sliderItem="latest-lesson-slider"
        max={{ mobile: 5, desktop: 4 }}
      />
      <LessonsCarousel
        title="Clases más vistas"
        data={popularLesson}
        containerId="monst-viewed-container"
        itemId="monst-viewed-item"
        sliderItem="most-view-slider"
        max={{ mobile: 3, desktop: 2 }}
      />
      <LearningPlan />
      <PublicPorfolioSection />
      <NewProjects />
      <ProjectsCompleted />
      <FreeLesson />
      <Testimonies />
      <ComunityReviews />
      <PricingSection withTitle />
      <Divider color="transparent" marginVertical={48} />
      <ProgresiveLearning />
      <PlanetSection />
      <Footer background="var(--darkBlue)" />
    </>
  )
}

Home.pageTheme = {
  background: 'var(--sky)',
}

export async function getStaticProps() {
  const queryClient = new QueryClient()
  await prefetchCourses(queryClient)
  await prefetchTotalUsers({ queryClient })
  await prefetchProjects(queryClient)
  await prefetchStudentProjects({ queryClient, page_size: 4 })
  await prefetchSubscriptionPlans({ queryClient, product: 'ALL' })
  await prefetchLatestLessons({ queryClient })
  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
    revalidate: 600,
  }
}

export default Home
