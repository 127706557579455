import styled from 'styled-components'
import { Typography } from 'common/typography'
import Wrapper from 'common/wrapper'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { MdDone } from 'react-icons/md'
import { SecondaryButton } from 'common/button'
import { PinkGradient } from './platform'

const SpacialHeroStyled = styled.section`
  margin-block-start: var(--headerBlockSize);
  margin-block-end: 80px;
  display: flex;
  flex-direction: column-reverse;
  .hero-container {
    margin-block-start: 32px;
  }
  h1 {
    ${PinkGradient}
  }
  .station {
    width: fit-content;
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
  }
  .subtitle a {
    color: var(--turquoise);
    text-decoration: underline;
  }
  .cohete {
    display: none;
  }
  .hero-content ul {
    margin-block-start: 1.5rem;
    margin-block-end: 1.5rem;
  }
  .hero-content ul li {
    display: flex;
    align-items: center;
    gap: .5rem;
    a {
      color: var(--turquoise);
      text-decoration: underline;
    }
  }
  @media(min-width: 1024px) {
    margin-block-start: 0;
    background: url('/images/home/blaze-gradients.svg') no-repeat;
    background-size: contain;
    background-position: right center;
    position: relative;
    flex-direction: row;
    .spacial-station {
      position: relative;
    }
    .hero-container {
      height: 100%;
      display: flex;
      align-items: center;
      max-inline-size: 690px;
      p {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }
  @media(min-width: 1366px) {
    .wrapper {
      padding: 0;
    }
    .cohete {
      display: block;
      position: absolute;
      left: -380px;
      bottom: 0;
    }
    h1 {
      min-width: 760px;
    }
  }
`

function SpacialHero() {
  return (
    <SpacialHeroStyled className="hero-section">
      <Wrapper maxWidth="1360">
        <div className="hero-container">
          <div className="hero-content">
            <Typography as="h1" small="headline4" large="headline1" color="var(--baseNeutral)">
              ¡Trabaja en tecnología!
            </Typography>
            <ul>
              <li>
                <MdDone size={24} color="#4BB543" />
                <p>
                  Aprende desde cero con nuestro
                  {' '}
                  <Link href="/aprender" passHref>
                    plan de estudios
                  </Link>
                </p>
              </li>
              <li>
                <MdDone size={24} color="#4BB543" />
                <p>
                  Practica con nuestros más de
                  {' '}
                  <Link href="/proyectos/todos" passHref>
                    100 proyectos
                  </Link>
                </p>
              </li>
              <li>
                <MdDone size={24} color="#4BB543" />
                <p>
                  Crea un perfil profesional
                </p>
              </li>
            </ul>
            <div className="hero-cta">
              <Link href="/aprender" passHref>
                <SecondaryButton height="48" fullWidth fitContent>
                  Iniciar ahora
                </SecondaryButton>
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="spacial-station">
        <div className="cohete">
          <Image width="386" height="155" src="/images/home/cohete-home.png" alt="Cohete neon que atraviesa el espacio con una estela de gas" priority />
        </div>
        <div className="station">
          <Image width="618" height="463" src="/images/home/spacial-station.webp" alt="Estacion espacial en la orbita de un sistema planetario" priority />
        </div>
      </div>
    </SpacialHeroStyled>
  )
}

export default SpacialHero
