import styled from 'styled-components'
import { Typography } from 'common/typography'
import Wrapper from 'common/wrapper'
import { useStudentProjects } from 'querys/student-projects'
import Link from 'next/link'
import { SecondaryButton } from 'common/button'
import ProjectCompleted from './project-completed'

const ProjectsCompletedStyled = styled.section`
  margin-block-start: 80px;
  margin-block-end: 80px;
  h2 {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
  }
  .completed-projects {
    margin-top: 48px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-template-rows: 1fr;
    gap: 24px;
  }
  .cta-container {
    display: flex;
    justify-content: center;
    margin-block-start: 40px;
  }
  @media (min-width: 768px) {
    margin-block-start: 160px;
    margin-block-end: 160px;
  }
`

function ProjectsCompleted() {
  const { data, isLoading, isError } = useStudentProjects({ page_size: 4 })
  if (isLoading || isError) return null

  const results = data?.pages[0]?.results

  return (
    <ProjectsCompletedStyled aria-labelledby="completed">
      <Wrapper maxWidth="1360">
        <Typography as="h2" small="headline5" large="headline3" id="completed" color="baseNeutral">
          Últimos proyectos completados por estudiantes
        </Typography>
        <ul className="completed-projects">
          {results.map((project) => (
            <ProjectCompleted key={project.uuid} {...project} />
          ))}
        </ul>
        <div className="cta-container">
          <Link href="/proyectos/estudiantes" passHref>
            <SecondaryButton fullWidth fitContent>
              Ver mas proyectos completados
            </SecondaryButton>
          </Link>
        </div>
      </Wrapper>
    </ProjectsCompletedStyled>
  )
}

export default ProjectsCompleted
