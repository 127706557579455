
import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import styles from './styles';
import Prism from 'prismjs';

class CodeBlock extends PureComponent {
  constructor(props){
    super(props);
  }

  componentDidMount(){
    Prism.highlightAll();
  }

  render(){
    const { classes } = this.props;
    const { data } = this.props;
    const className = `${classes.code} language-${data.language.toLowerCase()}`
    
    
    return (
      <pre className={classes.container}>
        <code className={className} >
          {data.text}
        </code>
      </pre>
    );
  }
};

CodeBlock.propTypes = {
  data: PropTypes.object,
};

CodeBlock.defaultProps = {
  style: '',
  data: {}
};

export default withStyles(styles)(CodeBlock);
