import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {
    margin: '15px 0',
    borderSpacing: '1px 2px',
  },
  th: {
    minWidth: '100px',
    padding: '8px 15px',
    borderRadius: '2px',
    backgroundColor: 'lightcoral',
  },
  td: {
    minWidth: '100px',
    padding: '8px 15px',
    borderRadius: '2px',
    textAlign: 'left',
  },
  tr: { },
}))

export default useStyles
