import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useRouter } from 'next/router'
import { getLessonService, getCourseLessonsService, getLatestLessonsService } from 'services/lesson'
import useSession from './session'

export async function prefetchCourseLessons({ queryClient, course }) {
  const token = ''
  await queryClient.prefetchQuery(
    ['GET_LESSONS', course, token],
    () => getCourseLessonsService({
      course,
      token,
    }),
  )
}

export function useLessons(courseSlug) {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { query } = router
  const course = courseSlug || query.course || query.course_slug
  const { token } = useSession()
  const lessonsQuery = useQuery(
    ['GET_LESSONS', course, token],
    () => getCourseLessonsService({
      course,
      token,
    }),
    {
      placeholderData: () => queryClient.getQueryData(['GET_LESSONS', course, '']),
      staleTime: Infinity,
    },
  )
  return lessonsQuery
}

export function useInitalLessons() {
  const queryClient = useQueryClient()
  const router = useRouter()
  const { query } = router
  const course = query.course || query.course_slug
  const lessonsQuery = useQuery(
    ['GET_INITIAL_LESSONS', course],
    () => getCourseLessonsService({
      course,
    }),
    {
      initialData: () => {
        const lessons = queryClient.getQueryData(['GET_LESSONS', course, ''])
        if (lessons) return lessons
      },
      staleTime: Infinity,
    },
  )
  return lessonsQuery
}

export async function prefetchLesson({
  queryClient, course, lesson_slug, token, req, res,
}) {
  await queryClient.prefetchQuery(
    ['GET_LESSON', course, lesson_slug, token],
    () => getLessonService({
      course,
      lesson_slug,
      token,
      req,
      res,
    }),
  )
}

export function useLessonKey() {
  const { course, lesson_slug } = useRouter().query
  const { token } = useSession()
  return (
    ['GET_LESSON', course, lesson_slug, token]
  )
}

export function useLesson() {
  const LESSON_KEY = useLessonKey()
  const [key, course, lesson_slug, token] = LESSON_KEY
  const lessonQuery = useQuery(
    LESSON_KEY,
    () => getLessonService({ course, lesson_slug, token }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: !!course,
    },
  )
  return { ...lessonQuery, token }
}

export function useNeighborLessons() {
  const { query: { course } } = useRouter()
  const { data } = useLesson()
  const queryClient = useQueryClient()
  const { token } = useSession()
  const setNeighborLessonsInfo = async () => {
    if (!data?.lesson_navigation) return
    const { prev, next } = data.lesson_navigation || {}
    if (prev) {
      const prevKey = ['GET_LESSON', course, prev.slug, token]
      const prevData = queryClient.getQueryData(prevKey)
      if (!prevData) {
        await queryClient.prefetchQuery(
          prevKey, () => getLessonService({ course, lesson_slug: prev.slug, token }),
        )
      }
    }
    if (next) {
      const nextKey = ['GET_LESSON', course, next.slug, token]
      const nextData = queryClient.getQueryData(nextKey)
      if (!nextData) {
        await queryClient.prefetchQuery(
          nextKey, () => getLessonService({ course, lesson_slug: next.slug, token }),
        )
      }
    }
  }
  useEffect(() => {
    setNeighborLessonsInfo()
  }, [data?.lesson_navigation])
}

export async function prefetchLatestLessons({ queryClient }) {
  await queryClient.prefetchQuery(
    'GET_LATEST_LESSONS',
    () => getLatestLessonsService(),
    {
      staleTime: Infinity,
    },
  )
}

export function useLatestLesssons() {
  return useQuery(
    'GET_LATEST_LESSONS',
    () => getLatestLessonsService(),
    {
      staleTime: Infinity,
    },
  )
}
