import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import useStyles from './styles';


const TableBlock = ({ data }) => {
  const classes = useStyles();

  if (!data) return '';

  let content = data.content || [];
  if (!Array.isArray(content) || content.length < 1) return '';

  const columnNames = content.shift();

  return (
    <table className={ classes.root }>
      <thead>
        <tr className={ classes.tr }>
          { 
            columnNames.map((columnName, index) => (
              <th key={`th-${index}`}className={ classes.th }>
                { ReactHtmlParser(columnName) }
              </th>
            )) 
          }
        </tr>
      </thead>
      <tbody>
        {
          content.map((row, index) => (
            <tr key={`tr-${index}`} className={classes.tr} style={{ backgroundColor: index % 2 === 0 ? 'white' : '#f9f9f9' }}>
              {
                Array.isArray(row) && row.length > 1 &&
                row.map((columnValue, index) => (
                  <td key={index} className={ classes.td }>
                    { ReactHtmlParser(columnValue) }
                  </td>
                ))
              }
            </tr>
          ))
        }
      </tbody>
    </table>
  );
};

export default TableBlock;
